<footer class="footer mt-auto bg-dark " style=" bottom: 0;
height: 40px;
left: 0;
position: fixed;
width: 100%;">
    <div class="container">
        <div class="row align-items-center justify-content-lg-between" style="color: white;">
            <div class="col-lg-6">
                <div class="copyright text-center  text-lg-left ">
                    &copy; 2021 <a class="font-weight-bold ml-1" routerLink="/aboutus">Kids-Dictionary</a>
                </div>
            </div>
            <div class="col-lg-6">
                <ul class="nav justify-content-center justify-content-lg-end">
                    <li class="nav-item">
                        <a class="nav-link" href="http://www.aswdc.in/About-Us" target="_blank">Powered by ASWDC</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>