<div class="bg">


    <audio autoplay loop *ngIf="playSoud === true">
        <source src="././assets/sound/Sound effect/dashboard_sound.ogg" type="audio/ogg">
    </audio>

    <div class="jscon cattext">
        <div>&nbsp;</div>
        <div><img src="././assets/images/Screen_Background/categories.webp" class="img-responsive" height="80vh"
                width="" alt="">
        </div>
        <div>&nbsp;</div>
    </div>


    <div class="jscon catlist">

        <div (click)="scrollLeft()">
            <i class="fas fa-chevron-left is "></i>
        </div>

        <div class="d-flex justify-content-center load" *ngIf="dataLoad === true">
            <div class="spinner-border text-info" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>

        <div *ngIf="dataLoad === false">
            <div class="scrollmenu" #widgetsContent id="scrollmenua">
                <a (click)="goCate(c.id)" *ngFor="let c of categorys">
                    <img src="https://kidsdictionary.arjunbala.com/{{c.img}}" class="cate mr-3" alt="">
                </a>
            </div>
        </div>

        <div (click)="scrollRight()">
            <i class="fas fa-chevron-right is"></i>
        </div>

    </div>


    <div class="row back">
        <div class="col-xl-1" (click)="goBack()">
            <img src="././assets/images/Levels_and_Other_Buttons/BackButton.webp" height="60px" alt="" srcset="">
        </div>
        <div class="col-xl-11"></div>
    </div>

</div>