<div class="bg" (keyup)="onKey($event)">


    <div class="jscon" style="padding-top: 3%;">
        <div>&nbsp;</div>
        <div>
            <ul id="main">
                <li *ngFor="let t of data; let idx = index">
                    <div class="spell" id="{{ 'wave' + t.latter + (idx+1) }}" style="background-image: url({{t.url}});">
                        <span class="txt">{{t.latter}}</span>
                    </div>
                </li>
            </ul>
        </div>
        <div>&nbsp;</div>
    </div>

    <div class="row middiv" *ngIf="dataLoad === true">
        <div class="spinner-border text-info loder" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>

    <div class="jscon" style="margin-top: 3vh;" *ngIf="dataLoad === false">
        <div class="">
            <div class="" *ngIf="index === 0; else elsePrev"><i class="fas fa-chevron-left icondis"
                    style="float: left;"></i>
            </div>
            <ng-template #elsePrev>
                <div (click)="changeSlid(-1)"><i class="fas fa-chevron-left is" style="float: left;"></i>
                </div>
            </ng-template>
        </div>

        <div class="" style="display: flex; justify-content: center;">
            <img src="{{imgurl}}" class="imgs" alt="">
        </div>

        <div class="">
            <div class="" *ngIf="index === (demo.length - 1); else elseNext"> <i class="fas fa-chevron-right icondis"
                    style="float: right;"></i></div>
            <ng-template #elseNext>
                <div class="" (click)="changeSlid(1)">
                    <i class="fas fa-chevron-right is" style="float: right;"></i>
                </div>
            </ng-template>
        </div>
    </div>

    <!-- <div class="row middiv" *ngIf="dataLoad === false">
        <div class="col-xl-4">
            <div class="" *ngIf="index === 0; else elsePrev"></div>
            <ng-template #elsePrev>
                <div (click)="changeSlid(-1)"><i class="fas fa-chevron-left is" style="float: left;"></i>
                </div>
            </ng-template>
        </div>

        <div class="col-xl-4" style="display: flex; justify-content: center;">
            <img src="{{imgurl}}" class="imgs" alt="">
        </div>

        <div class="col-xl-4">
            <div class="" *ngIf="index === (demo.length - 1); else elseNext"></div>
            <ng-template #elseNext>
                <div class="" (click)="changeSlid(1)">
                    <i class="fas fa-chevron-right is" style="float: right;"></i>
                </div>
            </ng-template>
        </div>
    </div> -->


    <div class="jscon ">
        <div (click)="goBack()" class="back">
            <img src="././assets/images/Levels_and_Other_Buttons/BackButton.webp" height="60px" alt="" srcset="">
        </div>
        <div></div>
        <div (click)="changeSlid(0)" id="SoundButton" class="sound">
            <img src="././assets/images/Levels_and_Other_Buttons/Sound button.webp" height="60px" alt="" srcset="">
        </div>
    </div>

</div>