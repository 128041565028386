<div>

    <div class="row" style="height: 80%;" *ngIf="aboutUs===false">
        <div class="col-md-2">&nbsp;</div>
        <div class="col-md-8">

            <router-outlet></router-outlet>
        </div>
        <div class="col-md-2">&nbsp;</div>
    </div>

    <div *ngIf="aboutUs===true">
        <router-outlet></router-outlet>
    </div>

    <div class="row"> &nbsp;</div>

</div>



<app-footer *ngIf="mobile===false"></app-footer>