<div *ngIf="mobile===false">

    <div class="bg">


        <div>

            <a (click)="aboutUs()">
                <img src="././assets/images/Screen_Background/DeveloperButtonmin.webp" class="devloperImg">
            </a>
        </div>

        <div class="levels">
            <img src="././assets/images/Levels_and_Other_Buttons/Engine.webp" class="anima" height="" alt="">
            <a (click)="goto(1)">
                <img src="././assets/images/Levels_and_Other_Buttons/Level-1.webp" class="anima" height="" alt="">
            </a>
            <a (click)="goto(2)">
                <img src="././assets/images/Levels_and_Other_Buttons/Level-2.webp" class="anima" height="" alt="">
            </a>
            <a (click)="goto(3)">
                <img src="././assets/images/Levels_and_Other_Buttons/Level-3.webp" class="anima" height="" alt="">
            </a>
            <a (click)="goto(4)">
                <img src="././assets/images/Levels_and_Other_Buttons/Level-4.webp" class="anima" height="" alt="">
            </a>

        </div>

        <br>
    </div>

    <audio autoplay loop *ngIf=" this.playSoud == true;" #audioOption id="sound">
        <source src="././assets/sound/Sound effect/" type="audio/ogg">
    </audio>

</div>










<!-- mobile -->
<!-- ------------------------------------------------------------------------------------------------------- -->














<div *ngIf="mobile === true" style="background-color: black; color: white;">

    <header class="w3-bar w3-top w3-hide-large w3-black w3-xlarge">

        <div class="w3-bar-item w3-padding-24 w3-wide">
            <img src="https://play-lh.googleusercontent.com/sIWVNCc5xR4_7Xbp6b3kNmBSD1dyNpK6JbXFzHFqByoeC3nilO9WqdOHynpLBgQgJq3A=s360-rw"
                alt="" class="mr-3" height="30px" style="float: left;" srcset=""> kids-dictonary

        </div>
    </header>

    <div class="w3-main" style="margin-left:250px">

        <div class="w3-hide-large" style="margin-top:83px"></div>

        <div class="w3-display-container w3-container">
            <h1><b>
                    Mobile App of
                    <span style="color:red;">
                        Kids-Dictionary
                    </span>
                    <br>

                    for your child
                </b>
            </h1>
        </div>

        <div class="w3-display-container w3-container">
            <h5>We alrady developed a mobile application for your kids</h5>
        </div>

        <div style="text-align: center; margin-top: 6%;">
            <a class=" btn btn-dark rounded-pill page-scroll"
                href="https://play.google.com/store/apps/details?id=com.aswdc_kidsdictionary" target="_blank"><i
                    class="fab fa-google-play"></i>PLAY STORE</a>
        </div>
    </div>

    <div class="mt-2 mb-5">
        <img src="././assets/images/Levels_and_Other_Buttons/app_mobile.jpg" alt="" width="{{wid}}" srcset="">
    </div>

    <div>
        <hr>
        <h2 style="text-align: center;"><b>Fetures</b></h2>
        <p class="w3-display-container w3-container" style="text-align: center;">
            Kid’s Dictionary is an app that helps kids learn their first words. The app has four levels that help kids
            improve their remembering skills. Word pronunciation with background music will make learning interesting.
        </p>

        <div class="col-lg-4">

            <div class="card left-pane first blbg">
                <div class="card-body">
                    <div class="text-wrapper">
                        <h4 class="card-title">Simple and easy to use UI</h4>
                        <p>It's simple and easy UI can help to use it.</p>
                    </div>
                </div>
            </div>

            <div class="card left-pane blbg">
                <div class="card-body">
                    <div class="text-wrapper">
                        <h4 class="card-title">Play sound for images shown</h4>
                        <p>In this play sound of shown images. so child learn with interest.</p>
                    </div>
                </div>
            </div>

            <div class="card left-pane blbg">
                <div class="card-body">
                    <div class="text-wrapper">
                        <h4 class="card-title">Free</h4>
                        <p>It's totally free web-app/app so anyone can use it</p>
                    </div>
                </div>
            </div>

        </div>

    </div>

    <div>
        <hr>
        <div>
            <h2 style="text-align: center;"><b>Download Now</b></h2>
            <h2 style="text-align: center;"><b>
                    <span style="color:red;" class="pt-5">Kids-Dictionary</span></b></h2>
        </div>

        <div style="text-align: center;" class="mt-5">
            <a class="btn btn-dark rounded-pill page-scroll"
                href="https://play.google.com/store/apps/details?id=com.aswdc_kidsdictionary" target="_blank"><i
                    class="fab fa-google-play"></i>PLAY STORE</a>
        </div>

        <div class="mt-2 mb-5">
            <img src="././assets/images/Levels_and_Other_Buttons/app_mobile.jpg" alt="" width="{{wid}}" srcset="">
        </div>

    </div>


    <hr>




    <!--  -->

    <div class="footer" id="contact">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="footer-col">
                        <h4><a href="http://www.aswdc.in/About-Us" target="_blank"
                                style="color:darkgray;text-decoration: none"><b>About Us</b></a></h4>
                        <h4><a href="http://www.aswdc.in/Contact-Us" target="_blank"
                                style="color: darkgray;text-decoration: none"><b>Contact Us</b></a></h4>

                    </div>
                </div>
                <div class="col-md-4">
                    <div class="footer-col middle">
                        <h4>Important Links</h4>
                        <ul class="list-unstyled li-space-lg">
                            <li class="media">
                                <i class="fas fa-circle mt-1"></i>
                                <div class="media-body ml-2">Our Developer Site : <a style="color: blue;"
                                        href="http://www.aswdc.in/" target="_blank">aswdc.in</a></div>
                            </li>
                            <li class="media">
                                <i class="fas fa-circle mt-1"></i>
                                <div class="media-body ml-2">Our College Site : <a style="color: blue;"
                                        href="http://www.darshan.ac.in/" target="_blank">darshan.ac.in</a></div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="footer-col last">
                        <h4>Social Media</h4>
                        <span class="fa-stack">
                            <a href="https://www.facebook.com/DarshanInstitute.Official" target="_blank">
                                <i class="fab fa-facebook-f fa-stack-1x "></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="https://twitter.com/darshan_inst" target="_blank">
                                <i class="fab fa-twitter fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="https://www.linkedin.com/school/darshan-institute-of-engineering-&amp;-technology/"
                                target="_blank">
                                <i class="fab fa-linkedin-in fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="https://www.instagram.com/darshan_inst/" target="_blank">
                                <i class="fab fa-instagram fa-stack-1x"></i>
                            </a>
                        </span>
                        <span class="fa-stack">
                            <a href="https://www.youtube.com/channel/UC3TJzmjHMJGtgK-jI4I-Eaw" target="_blank">
                                <i class="fab fa-youtube fa-stack-1x"></i>
                            </a>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>