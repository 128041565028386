<body>
    <!-- Main content -->
    <div class="main-content" id="panel">
        <!-- Topnav -->
        <nav class="navbar navbar-icon-top navbar-expand-lg navbar-dark bg-dark" style="max-height: 50px;">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="../" style="font-weight:550;">
                            <i class="fa fa-home"></i>
                            &nbsp;Home
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        <!-- Header -->
        <div class="header pb-6 d-flex align-items-center"
            style="min-height: 500px; background-image: url(././assets/images/aboutUs/diet.jfif); background-size: cover; background-position: center top;">
            <!-- Mask -->
            <span class="mask bg-gradient-default" style="opacity: .75 ;"></span>
            <!-- Header container -->
            <div class="container-fluid d-flex align-items-center">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <!-- text-shadow: 0 0 3px #d61b3c, 0 0 5px #d61b3c; -->
                        <h1 class="text-white" style="font-size:80px;">Darshan University</h1>
                    </div>
                </div>
            </div>
        </div>
        <!-- Page content -->
        <div class="container-fluid mt--8">
            <div class="row">
                <div class="col-xl-4 order-xl-2">
                    <div>
                        <iframe width="410" height="180"
                            src="https://www.youtube.com/embed/OuXurfZM3Mc?autoplay=1&mute=1" title="Darshan University"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen></iframe>
                    </div>
                    <div class="card card-profile">
                        <h1 class="col-xl-7" style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">Our
                            Motto</h1>
                        <div class="card-header" style="text-align: justify;font-size: 19px;">
                            <span style="font-weight:bolder;font-size: 20px;">" योगः कर्मसु कौशलम् "</span>
                            This verse advices us to perform our allocated duty in an excellent manner. Kaushalam
                            signifies doing work
                            with devotion and without attachment. Such detached attitude enhances its values and
                            improves the
                            concentration and skill of the worker. If we work with elegance, fortitude, and skill our
                            Body-Mind-Soul
                            will co-operate with our hands. Any work becomes valuable if carried out with full
                            concentration,
                            dedication, and abilities and also helps us to become valuable to others as well as to
                            society.
                        </div>
                    </div>
                </div>
                <div class="col-xl-8 order-xl-1">
                    <div class="card">
                        <div class="card-header">
                            <div class="row align-items-center">
                                <div class="col-7">
                                    <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">About
                                        Darshan University
                                    </h1>
                                </div>
                                <div class="col-5 text-right">
                                    <a href="https://www.darshan.ac.in" target="_blank" class="btn btn-sm"
                                        style="background-color: #d61b3c;color: white;font-weight: bolder;font-size: 13px;">website</a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <label
                                style="font-weight:650;text-align: justify;font-size: 20px;padding-bottom: 13px;">Darshan
                                University (DU), is a prominent organization offering a broad slate of academic programs
                                and
                                professional courses for undergraduate, graduate and postgraduate programs in
                                Engineering, Science &
                                Technology. The University is located in peaceful and sylvan surroundings with
                                distinctive collegiate
                                structure, about 19 km from Rajkot, Gujarat, India. It was established as an Engineering
                                Institute in
                                the year 2009, by Shree G. N. Patel Education & Charitable Trust with the objective to
                                impart quality
                                education and training in various fields of Engineering and Technology. It has now been
                                transformed to
                                the DARSHAN UNIVERSITY through an Act by the Government of Gujarat under Gujarat State
                                Private
                                Universities (Amendment) Act, 2021 (Act no. 15).<br><br>From its inception, the
                                organization has grown
                                steadily and created a unique identity in the field of Engineering & Technology by
                                implementing a skill
                                and training-based foundation for education. The academic environment at the campus
                                creates an ambiance
                                to promote creativity and exploration of technical skills. Darshan University is
                                committed towards
                                generation of knowledge, innovations and its contribution towards the development of the
                                Nation.</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header pb-6 d-flex align-items-center"
                style="min-height: 500px; background-image: url(././assets/images/aboutUs/laptop.jfif); background-size: cover; background-position: center top;">
                <span class="mask bg-gradient-default" style="opacity: .8 ;">
                </span>
                <div class="container-fluid d-flex align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="text-white" style="font-size:80px;">
                                ASWDC
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt--9">
                <div class="row">
                    <div class="col-xl-12 order-xl-1">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-3">
                                        <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">
                                            About ASWDC
                                        </h1>
                                    </div>
                                    <div class="col-9 text-right">
                                        <a href="http://www.aswdc.in" target="_blank" class="btn btn-sm"
                                            style="background-color: #d61b3c;color: white;font-weight: bolder;font-size: 13px;">
                                            website
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <label style="font-weight:650;text-align: justify;font-size: 20px;">
                                    ASWDC is established by Department of Computer Engineering where students work on
                                    live projects under
                                    guidance of staff and industry experts. Students are getting extensive knowledge and
                                    industrial
                                    experience of cutting edge technologies. ASWDC fills gap between academic curriculum
                                    and industry
                                    expectation.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="header pb-6 d-flex align-items-center"
                style="min-height: 500px; background-image: url(././assets/images/aboutUs/coding.jpeg); background-size: cover; background-position: center top;">
                <span class="mask bg-gradient-default" style="opacity: .8 ;">
                </span>
                <div class="container-fluid d-flex align-items-center">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <h1 class="text-white" style="font-size:80px;user-select: none;">
                                WE ARE
                                <span class="line-1 anim-typewriter">
                                    DEVELOPERS&nbsp;&nbsp;
                                </span>
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid mt--9">
                <div class="row">
                    <div class="col-xl-4 order-xl-2">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-10">
                                        <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">
                                            About Kids Dict</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <label
                                    style="font-weight:650;text-align: justify;font-size: 17.5px;padding-bottom: 10px;">
                                    Kid’s Dictionary is an web-app that helps kids learn their first words. The web-app
                                    has four
                                    levels that help kids improve their remembering skills. Word pronunciation with
                                    background music will make learning interesting. <br><br>
                                    Children learn as they play. Most importantly, in play children learn how to learn!!
                                    <br><br> Feel free to contact us if you would like a certain
                                    feature to be added or
                                    if you have a question or problem with anything. We hope you enjoy using this game!
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-8 order-xl-1">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-5">
                                        <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">MEET
                                            OUR TEAM</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <table class="table">
                                    <tr>
                                        <td style="font-size: 20px;">
                                            Developed by
                                        </td>
                                        <td style="font-size: 20px;">
                                            <a href="https://www.linkedin.com/in/vasu-dedakiya-4834531b4/"
                                                target="_blank">
                                                Vasu Dedakiya (190540107043)
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 20px;">
                                            Mentored by
                                        </td>
                                        <td style="font-size: 20px;">
                                            <a href="https://www.darshan.ac.in/faculty-profile/Prof-Vijay-Mansukhbhai-Shekhat"
                                                target="_blank">
                                                Prof. Vijay Shekhat<br>Computer Engineering Department
                                            </a>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 20px;">
                                            Explored by
                                        </td>
                                        <td style="font-size: 20px;">
                                            <a href="http://www.aswdc.in/" target="_blank">
                                                ASWDC<br>Computer Engineering Department
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="font-size: 20px;">
                                            Eulogized by
                                        </td>
                                        <td style="font-size: 20px;">
                                            <a href="https://www.darshan.ac.in/" target="_blank">
                                                Darshan University<br>Rajkot, Gujarat-INDIA
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-xl-6 order-xl-1">
                        <div class="card" id="contact-form">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-10">
                                        <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">How
                                            can we assist you?
                                        </h1>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <form autocomplete="off" (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm"
                                    name="myform">
                                    <h6 class="heading-small text-muted mb-4">Send your message to us for any kind of
                                        query or help</h6>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-name">Name</label>
                                                    <input type="text" id="input-name" class="form-control" ngModel
                                                        required #name="ngModel" placeholder="Your Name" name="name">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label" for="input-email">Email</label>
                                                    <input type="text" id="input-email" class="form-control" email
                                                        name="email" ngModel required #email="ngModel"
                                                        placeholder="Email Address">
                                                    <span style="font-weight: normal;" id="email-error"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label"
                                                        for="input-sunject">Subject</label>
                                                    <input type="text" id="input-subject" class="form-control" ngModel
                                                        required #subject="ngModel" placeholder="Your Subject"
                                                        name="subject">
                                                </div>
                                            </div>
                                            <div class="col-lg-6">
                                                <div class="form-group">
                                                    <label class="form-control-label"
                                                        for="input-email">Mo.Number</label>
                                                    <input type="number" id="input-number" class="form-control"
                                                        phonenumber name="number" ngModel required #number="ngModel"
                                                        placeholder="number">
                                                    <span style="font-weight: normal;" id="Number-error"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pl-lg-4">
                                        <div class="form-group">
                                            <label class="form-control-label" for="input-message">Message</label>

                                            <textarea rows="4" class="form-control"
                                                placeholder="tell us your suggestion & Mobile number" name="messages"
                                                ngModel required #messages="ngModel"></textarea>

                                        </div>
                                    </div>

                                    <div class="pl-lg-4">
                                        <div class="alert alert-primary" role="alert" *ngIf="sendingMail === true">
                                            Sending Mail
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden"></span>
                                            </div>
                                        </div>

                                        <div class="alert alert-success" role="alert" *ngIf="mailSended === true">
                                            Mail sended
                                        </div>

                                    </div>

                                    <div align="center" class="text-center">
                                        <div class="g-recaptcha" style="float: left;padding-left: 25px;"></div>
                                        <div style="float: right;padding-top: 20px;padding-right: 50px;">
                                            <input type="submit" class="btn btn-sm"
                                                style="background-color: #d61b3c;color: white;font-weight: bolder;font-size: 15px;float: right;"
                                                value="Submit">

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-6 order-xl-2">
                        <div class="card">
                            <div class="card-header">
                                <div class="row align-items-center">
                                    <div class="col-5">
                                        <h1 style="color:#d61b3c;font-size: 35px;border-bottom: 1.5px solid black;">
                                            Contact Us</h1>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-xl-6 order-xl-2">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d236029.38100822602!2d70.65316105978866!3d22.430566000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x28dc6eea8324e9d2!2sDarshan+Institute+of+Engineering+%26+Technology!5e0!3m2!1sen!2sin!4v1461218705004"
                                            width="100%" height="480" frameborder="0" style="border: 0"
                                            allowfullscreen="">
                                        </iframe>
                                    </div>
                                    <div class="col-xl-6 order-xl-1" style="padding-top:0px;">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row" style="padding-top: 40px;">
                                                    <div class="col-2" style="padding-top: 15%;font-size: 20px;">
                                                        <i class="fas fa-map-marker-alt">
                                                        </i>
                                                    </div>
                                                    <div style="display: inline-block;" class="col-10">
                                                        <h6 class="heading-small text-muted">At. Hadala, Near Water
                                                            Sump,<br>Rajkot - Morbi
                                                            Highway,<br>Rajkot - 363650,<br>Gujarat (INDIA)
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 15px;">
                                                    <div class="col-2" style="font-size: 20px;">
                                                        <i class="far fa-envelope">
                                                        </i>
                                                    </div>
                                                    <div style="display: inline-block;" class="col-10">
                                                        <h6 class="heading-small text-muted">
                                                            <a href="mailto:info@darshan.ac.in">
                                                                info@darshan.ac.in
                                                            </a>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 15px;">
                                                    <div class="col-2" style="padding-top: 3%;font-size: 20px;">
                                                        <i class="fas fa-phone-alt">
                                                        </i>
                                                    </div>
                                                    <div style="display: inline-block;" class="col-10">
                                                        <h6 class="heading-small text-muted">
                                                            <a href="tel:9727747310">
                                                                +91 - 97277 47310
                                                            </a>
                                                            <br>
                                                            <a href="tel:9727747311">
                                                                +91 - 97277 47311
                                                            </a>
                                                        </h6>
                                                    </div>
                                                </div>
                                                <div class="row" style="padding-top: 15px;padding-bottom: 0px;">
                                                    <div class="col-2" style="padding-top: 18%;font-size: 20px;">
                                                        <i class="far fa-clock">
                                                        </i>
                                                    </div>
                                                    <div style="display: inline-block;" class="col-10">
                                                        <h6 class="heading-small text-muted">
                                                            <div>
                                                                MON-FRI
                                                                <br>
                                                                <span style="padding-left: 12px;">
                                                                    07:45 AM - 04:20 PM
                                                                </span>
                                                            </div>
                                                            <div style="padding-top: 5px;">
                                                                SAT
                                                                <br>
                                                                <span style="padding-left: 12px;">
                                                                    07:45 AM - 02:00 PM
                                                                </span>
                                                            </div>
                                                            <div style="padding-top: 5px;">
                                                                SUN
                                                                <br>
                                                                <span style="padding-left: 12px;">
                                                                    Closed
                                                                </span>
                                                            </div>
                                                        </h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>